import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/navbar/navbar';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Home from './pages/Home/Home';

function App() {
  return (
    <>
    <Navbar/>
    <Routes>
  <Route exact path="/" element={<Home/>} />
  <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
</Routes>

    </>
  );
}

export default App;

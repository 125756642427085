import mockup from "../../assets/Mockup.png"
import "./home.css" 

export default function Home(){
    return (<div id="banner">
      <div id="info">
        <h1 id="title">Linc to the worldwide sporting community</h1>
        <h2 id="subHeading">Online platform for sporting Players, Coaches, Club executives and Supporters</h2>
        <div id="download"> <button className="downloadBtn">
          <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.51509 0.632263C1.19339 0.874044 0.969675 1.24636 0.96935 1.67588V31.0159C0.96935 31.4516 1.19323 31.8211 1.51753 32.0646L17.9681 16.3495L1.51509 0.632263Z" fill="#29B6F6"/>
<path d="M1.51509 0.632263C1.19339 0.874044 0.969675 1.24636 0.96935 1.67588V31.0159C0.96935 31.4516 1.19323 31.8211 1.51753 32.0646L17.9681 16.3495L1.51509 0.632263Z" fill="url(#paint0_linear_1_8)"/>
<path d="M23.0352 11.5078C16.2927 7.76261 7.91694 3.17447 2.92036 0.501378C2.43297 0.24145 1.9018 0.340327 1.51492 0.63118L17.968 16.3485L23.0352 11.5078Z" fill="#00E676"/>
<path d="M23.0352 11.5078C16.2927 7.76261 7.91694 3.17447 2.92036 0.501378C2.43297 0.24145 1.9018 0.340327 1.51492 0.63118L17.968 16.3485L23.0352 11.5078Z" fill="url(#paint1_linear_1_8)"/>
<path d="M1.51753 32.066C1.74035 32.2334 2.00329 32.351 2.30268 32.351C2.51362 32.351 2.7213 32.297 2.91987 32.1908C7.9158 29.5183 16.2891 24.9317 23.0307 21.1872L17.9681 16.351L1.51753 32.066Z" fill="#F44336"/>
<path d="M1.51753 32.066C1.74035 32.2334 2.00329 32.351 2.30268 32.351C2.51362 32.351 2.7213 32.297 2.91987 32.1908C7.9158 29.5183 16.2891 24.9317 23.0307 21.1872L17.9681 16.351L1.51753 32.066Z" fill="url(#paint2_linear_1_8)"/>
<path d="M29.6241 15.1988C27.8295 14.1828 25.5418 12.9035 23.0351 11.5112L17.9679 16.3518L23.0305 21.188C25.539 19.7948 27.8283 18.5147 29.6241 17.4981C30.0427 17.2612 30.3024 16.8209 30.3024 16.3482C30.3024 15.8754 30.0427 15.4352 29.6241 15.1988Z" fill="#FFEA00"/>
<path d="M29.6241 15.1988C27.8295 14.1828 25.5418 12.9035 23.0351 11.5112L17.9679 16.3518L23.0305 21.188C25.539 19.7948 27.8283 18.5147 29.6241 17.4981C30.0427 17.2612 30.3024 16.8209 30.3024 16.3482C30.3024 15.8754 30.0427 15.4352 29.6241 15.1988Z" fill="url(#paint3_linear_1_8)"/>
<path opacity="0.2" d="M2.82555 0.836796C9.73107 4.50363 23.058 11.7819 29.7293 15.5309C29.9728 15.6674 30.1542 15.8759 30.2714 16.1155C30.2014 15.7349 29.9731 15.3943 29.6245 15.1976C23.0028 11.4486 9.77493 4.17029 2.92077 0.503463C2.1011 0.0662883 1.15229 0.626998 1.0006 1.45366C1.31359 0.822799 2.11722 0.461796 2.82555 0.836796Z" fill="white"/>
<path opacity="0.1" d="M2.82555 31.8641C9.73107 28.1973 23.058 20.919 29.7293 17.17C29.9728 17.0335 30.1542 16.825 30.2714 16.5854C30.2014 16.966 29.9731 17.3066 29.6245 17.5034C23.0028 21.2523 9.77493 28.5306 2.92077 32.1974C2.1011 32.6346 1.15229 32.0739 1.0006 31.2473C1.31359 31.8781 2.11722 32.2391 2.82555 31.8641Z" fill="black"/>
<path d="M29.6243 15.1967C27.8297 14.1805 7.9171 3.1747 2.92052 0.50139C2.43314 0.241441 1.90197 0.340326 1.51509 0.631202C1.19339 0.873002 0.969675 1.24535 0.96935 1.67491V31.0172C0.96935 31.453 1.19323 31.8225 1.51753 32.066C1.74035 32.2334 2.00329 32.351 2.30268 32.351C2.51362 32.351 2.7213 32.297 2.91987 32.1908C7.9158 29.5181 27.8286 18.5128 29.6243 17.4962C30.0429 17.2592 30.3027 16.8189 30.3027 16.3461C30.3027 15.8733 30.0429 15.433 29.6243 15.1967Z" fill="url(#paint4_linear_1_8)"/>
<defs>
<linearGradient id="paint0_linear_1_8" x1="9.46874" y1="3.14003" x2="9.46874" y2="24.3539" gradientUnits="userSpaceOnUse">
<stop stop-color="#00B8D4" stop-opacity="0"/>
<stop offset="1" stop-color="white" stop-opacity="0.3"/>
</linearGradient>
<linearGradient id="paint1_linear_1_8" x1="0.124972" y1="3.61194" x2="21.9676" y2="13.7973" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.2"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_1_8" x1="9.61553" y1="23.968" x2="14.9327" y2="29.2852" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0.1"/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_1_8" x1="21.1876" y1="13.1321" x2="27.6236" y2="19.5681" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0.05"/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_1_8" x1="-4.51395" y1="13.1625" x2="25.4136" y2="27.118" gradientUnits="userSpaceOnUse">
<stop stop-color="white" stop-opacity="0.2"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</linearGradient>
</defs>
</svg> <h3 className="btnTitle">Playstore</h3>
</button> 
<button className="downloadBtn" ><svg id="appleLogo" viewBox="0 0 170 170" fill="currentColor">
  <title  >Apple Logo</title>
  <path d="M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z"/>
</svg> <h3 className="btnTitle">Appstore</h3>
</button>  </div>
      </div>
      <img src={mockup} id="mockup" />
    </div>);
} 
import './privacyPolicy.css'

export default function PrivacyPolicy(){
    return (
        <>
            <div id="content">  
      <h1>Privacy Policy</h1>
      <p>PlayerLinc’s mission is to give power to its members creating a Linc to the worldwide sporting community. Integral to this mission is our commitment to transparency being open about the data we collect, how it is used and with who it’s shared.
         This Privacy Policy applies when you use our Services. We offer our users choices about the data we collect, use and share as described in this Privacy Policy
      </p>

<h2>Introduction</h2>
<p>We are a Sport social network and online platform for sporting players, coaches, club executives and supporters. People use our Services to find and be found for opportunities within the sports community, to connect with others and find information. Our Privacy Policy applies to any Member and registered users (“Members”) who will share their professional identities, engage with their network, exchange knowledge and professional insights, post and view relevant content, learn and develop skills, and find career opportunities within sport. Content and data on some of our Services is viewable to non-members (“Visitors”).
We use the term “Designated Countries” to refer to countries in the European Union (EU), European Economic Area (EEA), and Switzerland.
</p>

<h2>Services</h2>
<p>This Privacy Policy applies to your use of our Services.
This Privacy Policy applies to PlayerLinc and associated PlayerLinc companies.
</p>

<h2>Data Controllers and Contracting Parties</h2>
<p>If you are outside of the Designated Countries, Playerlinc Pty Ltd will be the controller of your personal data provided to, or collected by or for, or processed in connection with, our Services.
As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates.
</p>

<h2>Change</h2>
<p>Changes to the Privacy Policy apply to your use of our Services after the “effective date.”
PlayerLinc (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account.

You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.
</p>

<h2>Data Collected</h2>
<h3>Registration</h3>
<p>
To Register with PlayerLinc you need to provide data including your name, email address and/or mobile number, and a password. For Quick Boosting and package subscriptions, you will need to provide payment (e.g., credit card) and billing information.
</p>

<h3>Profile</h3>
<p>
You choose the information on your profile, such as sporting role, current sporting status and You have no obligation to provide additional information; however, profile information helps you to get more from our Services, including helping coaches and recruiters see more about you enhancing your opportunities. It’s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available.
</p>

<h3>Posting and Uploading</h3>
<p>We collect personal data from you when you provide, post or upload it to our Services, such as when you quick boost, (e.g., with demographic data or geographical information), we receive your contacts (including contact information your service provider(s) or app automatically added to your address book when you communicated with addresses or numbers not already in your list).

You have no obligations to post or upload personal data; though if you don’t, it may limit your ability to grow and engage with your network over our Services.

You and others may post content that includes information about you (as part of articles, posts, comments, videos) on our Services. We also may collect public information about you, such as sports achievements and accomplishments, and make it available as part of our Services, this use of such information can be controlled by you, permitted by your settings option
</p>

<h3>Partners</h3>
<p>
We receive personal data about you when you use the services of our customers and partners, such as club details, playing stats and records 

We log usage data when you visit or otherwise use our Services, including our sites, app and platform technology, such as when you view or click on content, perform a search, install or update one of our mobile apps, share articles or upload your stats. We use log-ins & device information and internet protocol (“IP”) addresses to identify you and log your use.
</p>

<h3>Device and Location</h3>
<p>
When you visit or leave our Services. we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location.
</p>

<h3>Messages</h3>
<p>
If you communicate through our Services, We collect information about you when you send, receive, or engage with messages in connection with our Services. For example, if you get a Playerlinc connection request, we track whether you have acted on it We also use automatic scanning technology on messages to support and protect our site. For example, we use this technology to suggest possible responses to messages and to manage or block content that violates our User Agreement or Professional Community Policies from our Services.
</p>

<h2>2. How We Use Your Data</h2>
<p>We use your data to provide, support, personalize and develop our Services. How we use your personal data will depend on which Services you use, how you use those Services and the choices you make in your settings. We use the data that we have about you to provide and personalize our Services</p>
 <h3>Services</h3>
 <p>Our Services help you connect with others, find opportunities, stay informed, get educated We use your data to authorize access to our Services and Honor your settings.

Our Services allow you to stay in touch and up to date with friends, teammates, coaches scouts, and executive sand other professional contacts. To do so, you can “Linc” with the professionals who you choose, and who also wish to “Linc” with you. Subject to your and their settings, when you connect with other Members, you will be able to search each others’ connections in order to expand your list of contacts 

We use data about you (such as your profile, profiles you have viewed or data provided) to help others find your profile, suggest connections for you and others (e.g. Members who share your contacts or highlights) and enable you to invite others to become a Member and Linc with you. You can also opt-in to allow us to use your precise location or proximity to others for certain tasks (e.g. to suggest other nearby Members for you to Linc with, calculate the distance for new clubs, or notify your connections that you are at a professional event).

It is your choice whether to invite someone to our Services, send a connection request, or allow another Member to become your connection. When you invite someone to connect with you, your invitation will include your network and basic profile information (e.g., name, profile photo, job title, region). We will send invitation reminders to the person you invited. You can choose whether or not to share your own list of connections with your connections.

Our Services allow you to stay informed about news, events and ideas regarding Sporting topics you care about, and from professionals you respect. Our Services also allow you to improve your professional skills or learn new ones. We use the data we have about you (e.g., data you provide, data we collect from your engagement with our Services and inferences we make from the data we have about you), to personalize our Services for you, such as by recommending or ranking relevant content and conversations on our Services. We also use the data we have about you to suggest skills you could add to your profile and skills that you might need to pursue your next opportunity. we will use this information to personalize content in your feed, suggest that you follow certain members on our site, or suggest related learning content to help you. We use your content, activity and other data, including your name and photo, to provide notices to your network and others. For example, subject to your settings, we may notify others that you have updated your profile, posted content, took a social action, used a feature, made new connections or were mentioned in the news.

</p>

<h3>Career</h3>
<p>Our Services allow you to explore, seek out, and be found for careers within sport, we also assist to provide and evaluate further development opportunities. You can signal that you are interested in changing clubs and share information with recruiters and scouts. We will use your data to recommend positions available to you and you to recruiters. Keeping your profile accurate and up-to-date may help you better connect to others and to opportunities through our Services.</p>

<h3>Paid Package Options</h3>
<p>
Our Paid Package Options help paying users to search for and contact Members through our Services. We sell Paid Package Options that provide our customers and subscribers with customized-advanced search functionality (including messaging and activity alerts) We do not provide contact information to customers as part of these package options without your consent. Paid subscribers can store information they have about you in our premium Services, such as a playing history, stats or contact information. 
</p>

<h3>Communications</h3>
<p>We contact you and enable communications between Members. We offer settings to control what messages you receive and how often you receive some types of messages.

We will contact you through email, mobile phone, notices posted on our websites or apps, messages to your PlayerLinc inbox and other ways through our Services, including text messages and push notifications. We will send you messages about the availability of our Services, security, or other service-related issues. We also send messages about how to use our Services, network updates, reminders, position availability suggestions and promotional messages from us and our partners. You may change your communication preferences at any time. Please be aware that you cannot opt out of receiving service messages from us, including security and legal notices.

</p>

<h3>Marketing</h3>
<p>
We promote our Services to you and others.

In addition to advertising our Services, we use Members’ data and content for invitations and communications promoting membership and network growth, engagement and our Services, such as by showing your connections that you have used a feature on our Services.

Developing Services and Research
We develop our Services and conduct research

Service Development
We use data, including public feedback, to conduct research and development for our Services. This is done to provide you and others with a better, more intuitive and personalized experience, drive membership growth and engagement on our Services, and help connect professionals to each other and to increased sporting opportunity.

Other Research
We seek to create multi-level sport opportunity for Members of the sporting community and to help them be more productive and successful. 

Customer Support
We use data to help you and fix problems.

We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).

Security and Investigations

We use data for security, fraud prevention and investigations.
We use your data (including your communications) for security purposes or to prevent or investigate possible fraud or other violations of our User Agreement and/or attempts to harm our Members, Visitors or others.
</p>

<h2>3. Information Sharing</h2>
<h3>Our Services</h3>
<p>
Any data that you include on your profile and any content you post or social action (e.g., likes, follows, comments, shares) you take on our Services will be seen by others, consistent with your settings.</p>


<h3>Profile</h3>
<p>Your profile is fully visible to all Members and customers of our Services. Subject to your settings, it can also be visible to others on or off our Services (e.g., Visitors to our Services or users of third- party search engines). Your settings, degree of connection with the viewing Member, the paid subscriptions they may have, their usage of our Services and search types (e.g., by name or by keyword) impact the availability of your profile and whether they can view certain fields in your profile.
</p>

<h3>Posts, Likes, Follows, Comments, Messages</h3>
<p>Our Services allow viewing and sharing information including through posts, likes, follows and comments.

When you share a post (e.g., an update, image, video or article) publicly it can be viewed by everyone and re-shared anywhere (subject to your settings). Members, Visitors and others will be able to find and see your publicly-shared content, including your name (and photo if you have provided one).
In a group, posts are visible to others in the group. Your membership in groups is public and part of your profile, but you can change visibility in your settings.
Any information you share through companies’ or other organizations’ pages on our Services will be viewable by it and others who visit those pages.
When you Linc with a member or organization, you are visible to others and that “page owner” as a follower.
We let senders know when you act on their message, subject to your settings where applicable.
Subject to your settings, we let a Member know when you view their profile.
When you like or re-share or comment on another’s content (including ads), others will be able to view these “social actions” and associate it with you (e.g., your name, profile and photo if you provided it).
Your employer will not see your job searches or personal messages. Regulated Members may need to store communications outside of our Service.

</p>

<h3>Others’ Services</h3>
<p>You may link your account with others’ services so that they can look up your contacts’ profiles, post your shares on such platforms, or enable you to start conversations with your connections on such platforms. Excerpts from your profile will also appear on the services of others.
Subject to your settings, other services may look up your profile. When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. For example, you may link your Twitter or WeChat account to share content from our Services into these other services, or your email provider may give you the option to upload your LinkedIn contacts into its own service. Third-party services have their own privacy policies, and you may be giving them permission to use your data in ways we would not. You may revoke the link with such accounts.

Subject to your settings, excerpts from your profile will appear on the services of others (e.g., search engine results, mail and calendar applications that show a user limited profile data of the person they are meeting or messaging, social media aggregators, talent and lead managers). “Old” profile information remains on these services until they update their data cache with changes you made to your profile.
</p>

<h3>Related Services</h3>
<p>We share your data across our different Services and PlayerLinc affiliated entities.

We will share your personal data with our affiliates to provide and develop our Services. We may combine information internally across the different Services covered by this Privacy Policy to help our Services be more relevant and useful to you and others. For example, we may personalize your feed or job recommendations based on your learning history.
</p>

<h3>Service Providers</h3>
<p>We may use others to help us with our Services.

We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.
</p>

<h3>Legal Disclosures</h3>
<p>We may need to share your data when we believe it’s required by law or to help protect the rights and safety of you, us or others.

It is possible that we will need to disclose information about you when required by law, subpoena, or other legal process or if we have a good faith belief that disclosure is reasonably necessary to (1) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (2) enforce our agreements with you; (3) investigate and defend ourselves against any third-party claims or allegations; (4) protect the security or integrity of our Services (such as by sharing with companies facing similar threats); or (5) exercise or protect the rights and safety of LinkedIn, our Members, personnel or others. We attempt to notify Members about legal demands for their personal data when appropriate in our judgment, unless prohibited by law or court order or when the request is an emergency. We may dispute such demands when we believe, in our discretion, that the requests are overbroad, vague or lack proper authority, but we do not promise to challenge every demand. To learn more see our Data Request Guidelines and Transparency Report.

</p>

<h3>Change in Control or Sale</h3>
<p>We may share your data when our business is sold to others, but it must continue to be used in accordance with this Privacy Policy.

We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.
</p>

<h2>4. Your Choices & Obligations</h2>
<h3>Data Retention</h3>
<p>We keep most of your personal data for as long as your account is open.

We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services. Even if you only use our Services when looking for a new job every few years, we will retain your information and keep your profile open, unless you close your account. In some cases we choose to retain certain information (e.g., insights about Services use) in a depersonalized or aggregated form.

</p>

<h3>Rights to Access and Control Your Personal Data</h3>
<p>You can access or delete your personal data. You have many choices about how your data is collected, used and shared.

We provide many choices about the collection, use and sharing of your data, from deleting or correcting data you include in your profile and controlling the visibility of your posts to advertising opt-outs and communication controls. We offer you settings to control and manage the personal data we have about you.
</p>

<h3>For personal data that we have about you, you can:</h3>
<p>
You may also contact us using the contact information below, and we will consider your request in accordance with applicable laws.

Residents in the Designated Countries and other regions may have additional rights under their laws.

</p>

<h3>Account Closure</h3>
<p>We keep some of your data even after you close your account.

If you choose to close your PlayerLinc account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.

We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your account for breach of our Professional Community Policies), enforce our User Agreement, or fulfill your request to "unsubscribe" from further messages from us. We will retain de-personalized information after your account has been closed.

Information you have shared with others (e.g., through InMail, updates or group posts) will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members have copied out of our Services. Groups content and ratings or review content associated with closed accounts will show an unknown user as the source. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.

Delete Data: You can ask us to erase or delete all or some of your personal data (e.g., if it is no longer necessary to provide Services to you).
Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases, particularly if it’s inaccurate.
Object to, or Limit or Restrict, Use of Data: You can ask us to stop using all or some of your personal data (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal data is inaccurate or unlawfully held).
Right to Access and/or Take Your Data: You can ask us for a copy of your personal data and can ask for a copy of personal data you provided in machine readable form.

</p>

<h2>5. Other Important Information</h2>
<h3>Security</h3>
<p>We monitor for and try to prevent security breaches. Please use the security features available through our Services.

We implement security safeguards designed to protect your data, such as HTTPS. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. 

</p>

<h3>Cross-Border Data Transfers</h3>
<p>We store and use your data outside your country.

We process data both inside and outside of Australia and rely on legally-provided mechanisms to lawfully transfer data across borders. Learn more. Countries where we process data may have laws which are different from, and potentially not as protective as, the laws of your own country.
</p>

<h3>Lawful Bases for Processing</h3>
<p>We have lawful bases to collect, use and share data about you. You have choices about our use of your data. At any time, you can withdraw consent you have provided by going to settings.

We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent(where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g., to deliver the PlayerLinc Services you have requested) and “legitimate interests.”

Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object. Learn More. 

</p>

<h3>Direct Marketing and Do Not Track Signals</h3>
<p>Our statements regarding direct marketing and “do not track” signals.

We currently do not share personal data with third parties for their direct marketing purposes without your permission.
</p>

<h3>Contact Information</h3>
<p>You can contact us or use other options to resolve any complaints.</p>
    

<p>

If you have questions or complaints regarding this Policy, please first contact PlayerLinc online. E: Hi@playerlinc.com.au</p>
    </div>
<footer><h4>Contact -  Hi@playerlinc.com.au</h4></footer>
        </>
    );
}
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./navbar.css";
import { NavLink } from "react-router-dom";


export default function Navbar(){
    return (
        <nav><img src={logo} id='logo' />    <div id="links">
        <li>
          <NavLink className={({ isActive }) => (isActive ? "link-active": "link")} to="/"><p>Home</p></NavLink>
        </li>
        {/* <li>
          <NavLink  className={({ isActive }) => (isActive ? "link-active" : "link")}><p>About</p></NavLink>
        </li>      */}
        <li>
          <NavLink  className={({ isActive }) => (isActive ? "link-active" : "link")} to="/privacy-policy"><p>Privacy Policy</p></NavLink>
        </li>
        {/* <li>
          <NavLink   className={({ isActive }) => (isActive ? "link-active" : "link")}><p>Tearms and Condition</p></NavLink>
        </li> */}
      </div></nav>
    );
} 